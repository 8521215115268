import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    Sidebar_drawer: null,
    Sidebar_expand: true,
    Customizer_drawer: false,
    SidebarColor: 'white',
    SidebarBg: '',
    ThemeColor: '#1e88e5',
    listadoProyeccionMO: []
  },
  mutations: {
    SET_SIDEBAR_DRAWER(state, payload) {
      state.Sidebar_drawer = payload;
    },
    SET_SIDEBAR_EXPAND(state, payload) {
      console.log(payload, 'expand');
      state.Sidebar_expand = payload;
    },
    SET_CUSTOMIZER_DRAWER(state, payload) {
      state.Customizer_drawer = payload;
    },
    SET_SIDEBAR_COLOR(state, payload) {
      state.SidebarColor = payload;
    },
    SET_THEME_COLOR(state, payload) {
      state.ThemeColor = payload;
    },
    SET_PROYECCION_MO(state, payload) {
      state.listadoProyeccionMO = payload.map((element, index) => {
        return { numero: index + 1, ...element };
      });
    }
  },
  actions: {
    cargarProyeccionMO(context, listado) {
      context.commit('SET_PROYECCION_MO', listado);
    }
  },
  modules: {},
  getters: {
    listadoProyeccionMO: (state) => state.listadoProyeccionMO,
    SidebarDrawer: (state) => state.Sidebar_drawer,
    SidebarExpand: (state) => state.Sidebar_expand,
    SidebarColor: (state) => state.SidebarColor,
    ThemeColor: (state) => state.ThemeColor
  }
});
