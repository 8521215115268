import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@fortawesome/fontawesome-free/css/all.css';
import '@/scss/vuetify/overrides.scss';
Vue.use(Vuetify);

const theme = {
  primary: '#1e88e5', // "#1e88e6", "#21c1d6", "#fc4b6c", "#563dea", "#9C27b0", "#ff9800"
  info: '#1e88e5',
  success: '#21c1d6',
  accent: '#fc4b6c',
  default: '#563dea'
};

export default new Vuetify({
  theme: {
    themes: {
      dark: theme,
      light: theme
    },
    dark: false
  },
  icons: {
    iconfont: 'fa'
  },
  rtl: false
});
