<template>
  <div class="cnt-load">
    <div class="main-loader">
      <span class="loader1"></span>
      <span class="loader2"></span>
      <span class="loader3"></span>
    </div>
    <h2 class="cnt-load-desc">CARGANDO CONFIGURACIONES<span>&nbsp;</span></h2>
  </div>
</template>

<script>
export default {
  name: 'LoadSetting'
};
</script>

<style lang="scss" scoped>
body {
  background: #fff;
}
.cnt-load {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto', sans-serif;
}

.cnt-load-desc {
  margin-top: 100px;
  color: #111a28;
  //position: relative;
  //float: left;
  //background: #fff;
}

//.cnt-load-desc span {
//  position: absolute;
//  right: 0;
//  width: 0;
//  background: #fff;
//  border-left: 1px solid #000;
//  animation: escribir 5s steps(30) infinite alternate;
//}

//@keyframes escribir {
//  from {
//    width: 100%;
//  }
//  to {
//    width: 0;
//  }
//}

@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(0.2, 0.2, 0.2);
    transform: scale3d(0.2, 0.2, 0.2);
  }
}
@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(0.2, 0.2, 0.2);
    transform: scale3d(0.2, 0.2, 0.2);
  }
}
@-webkit-keyframes pulse2 {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(0, 0, 0);
    transform: scale3d(0, 0, 0);
  }
}
@keyframes pulse2 {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(0, 0, 0);
    transform: scale3d(0, 0, 0);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@-webkit-keyframes pulse3 {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(0, 0, 0);
    transform: scale3d(0, 0, 0);
  }
  90% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes pulse3 {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(0, 0, 0);
    transform: scale3d(0, 0, 0);
  }
  90% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.main-loader {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
}
.loader1 {
  width: 20px;
  height: 20px;
  background-color: #111a28;
  border-radius: 50%;
  float: left;
  z-index: 9;
  position: absolute;
  left: 20px;
  top: 20px;
  -webkit-animation-name: pulse;
  animation-name: pulse;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: center;
  animation-delay: 0s;
  transition: transform 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.loader2 {
  width: 40px;
  height: 40px;
  background-color: #111a28;
  border-radius: 50%;
  float: left;
  z-index: 8;
  opacity: 0.7;
  position: absolute;
  left: 10px;
  top: 10px;
  -webkit-animation-name: pulse2;
  animation-name: pulse2;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 0.3s;
  transform-origin: center;
  transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.loader3 {
  width: 60px;
  height: 60px;
  background-color: #111a28;
  border-radius: 50%;
  float: left;
  z-index: 7;
  opacity: 0.4;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-animation-name: pulse3;
  animation-name: pulse3;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 0.5s;
  transform-origin: center;
  transition: transform 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
}
</style>
