import Vue from 'vue';
import VueRouter from 'vue-router';
import goTo from 'vuetify/es5/services/goto';

Vue.use(VueRouter);

// eslint-disable-next-line no-sparse-arrays
const routes = [
  {
    path: '/',
    redirect: '/auth/login',
    meta: {
      code: '*'
    }
  },
  {
    path: '/auth',
    component: () => import(/* webpackChunkName: "group-auth" */ '@/layouts/blank-layout/Blanklayout'),
    meta: {
      code: '*'
    },
    children: [
      {
        name: 'authlogin',
        path: 'login',
        component: () => import(/* webpackChunkName: "group-auth-login" */ '@/giplan/authentication/views/Login'),
        meta: {
          code: '*'
        }
      }
    ]
  },
  /************************ giplan RUTAS ******************************/
  {
    path: '/inicio',
    component: () => import(/* webpackChunkName: "group-inicio" */ '@/layouts/full-layout/Layout'),
    meta: {
      code: '*'
    },
    children: [
      {
        name: 'inicio',
        path: '',
        component: () => import(/* webpackChunkName: "group-inicio-inicio" */ '@/giplan/inicio/inicio/views/Inicio'),
        meta: {
          code: '*'
        }
      }
    ]
  },
  {
    path: '/analytics',
    component: () => import(/* webpackChunkName: "group-analytics" */ '@/layouts/full-layout/Layout'),
    children: [
      {
        name: 'analytics_dashboard',
        path: 'dashboard',
        component: () =>
          import(
            /* webpackChunkName: "group-analytics-dashboard" */ '@/giplan/dashboard/analytics/views/DashboardAnalytics'
          )
      }
    ]
  },
  {
    path: '/actas',
    component: () => import(/* webpackChunkName: "group-actas" */ '@/layouts/full-layout/Layout'),
    meta: {
      code: 'gh-act'
    },
    children: [
      {
        name: 'actas_dashboard',
        path: 'dashboard',
        component: () =>
          import(/* webpackChunkName: "group-actas-dashboard" */ '@/giplan/actas/dashboard/views/Dashboard'),
        meta: {
          code: 'gh-act-dash'
        }
      },
      {
        name: 'actas_reunion',
        path: 'actas-reunion',
        props: (route) => ({ idreferencia: route.query.i, referencia: route.query.r }),
        component: () =>
          import(/* webpackChunkName: "group-actas-reunion" */ '@/giplan/actas/actas_reunion/views/ActasReunion'),
        meta: {
          code: 'gh-act-act_reu'
        }
      },
      {
        name: 'actas_seguimiento',
        path: 'actas-seguimiento',
        props: (route) => ({ idreferencia: route.query.i, referencia: route.query.r }),
        component: () =>
          import(/* webpackChunkName: "group-actas-reunion" */ '@/giplan/actas/actas_reunion/views/MiniActasReunion'),
        meta: {
          code: 'gh-act-act_seg'
        }
      },
      {
        name: 'actas_matriz_aac',
        path: 'matriz-aac',
        component: () =>
          import(/* webpackChunkName: "group-actas-matriz" */ '@/giplan/actas/matriz_actas/views/MatrizActas'),
        meta: {
          code: 'gh-act-mat_aac'
        }
      },
      {
        name: 'actas_mis_actas',
        path: 'mis-actas',
        component: () =>
          import(/* webpackChunkName: "group-actas-matriz" */ '@/giplan/actas/matriz_actas/views/MisActas'),
        meta: {
          code: 'gh-act-mat_aac'
        }
      }
    ]
  },
  {
    path: '/proyectos',
    component: () => import(/* webpackChunkName: "group-proyectos" */ '@/layouts/full-layout/Layout'),
    meta: {
      code: 'gh-proy'
    },
    children: [
      {
        name: 'proyectos_registro',
        path: 'registro',
        component: () =>
          import(/* webpackChunkName: "group-proyectos-dashboard" */ '@/giplan/proyectos/proyecto/views/Proyecto'),
        meta: {
          code: 'gh-proy-cre_proy'
        }
      },
      {
        name: 'proyectos_matriz',
        path: 'matriz',
        component: () =>
          import(/* webpackChunkName: "group-proyectos-gantt" */ '@/giplan/proyectos/matriz/views/Matriz'),
        meta: {
          code: 'gh-proy-mat_seg'
        }
      },
      {
        name: 'proyectos_matriz_general',
        path: 'general',
        component: () =>
          import(/* webpackChunkName: "group-proyectos-gantt" */ '@/giplan/proyectos/matriz/views/MatrizProyecto'),
        meta: {
          code: 'gh-proy-mis_proy'
        }
      }
    ]
  },
  {
    path: '/okr',
    component: () => import(/* webpackChunkName: "group-proyectos" */ '@/layouts/full-layout/Layout'),
    meta: {
      code: 'gh-okr'
    },
    children: [
      {
        name: 'metodologia_okr',
        path: 'metodologia-okr',
        component: () =>
          import(
            /* webpackChunkName: "group-proyectos-dashboard" */ '@/giplan/okr/metodologia-okr/views/MetodologiaOKR'
          ),
        meta: {
          code: 'gh-okr-cre_okr'
        }
      },
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () =>
          import(/* webpackChunkName: "group-proyectos-dashboard" */ '@/giplan/okr/dashboard/views/Dashboard'),
        meta: {
          code: 'gh-okr-dash'
        }
      },
      {
        name: 'okr/matriz_seguimiento',
        path: 'matriz-seguimiento',
        component: () =>
          import(
            /* webpackChunkName: "group-actas-dashboard" */ '@/giplan/okr/matriz-seguimiento/views/MatrizSeguimiento'
          ),
        meta: {
          code: 'gh-okr-mat_seg'
        }
      },
      {
        name: 'okr/matriz',
        path: 'matriz',
        component: () => import(/* webpackChunkName: "group-actas-dashboard" */ '@/giplan/okr/matriz/views/Matriz'),
        meta: {
          code: 'gh-okr-okrs'
        }
      }
    ]
  },
  {
    path: '/presupuesto',
    component: () => import(/* webpackChunkName: "group-proyectos" */ '@/layouts/full-layout/Layout'),
    meta: {
      code: 'gh-pres'
    },
    children: [
      {
        name: 'presupuesto_dashboard',
        path: 'dashboard',
        component: () =>
          import(/* webpackChunkName: "group-proyectos-dashboard" */ '@/giplan/presupuesto/dashboard/views/Dashboard'),
        meta: {
          code: 'gh-pres-dash'
        }
      },
      {
        name: 'presupuesto_dashboard_2',
        path: 'dashboard-presupuesto',
        component: () => import('@/giplan/presupuesto/dashboard2/views/Dashboard'),
        meta: {
          code: 'gh-pres-dash'
        }
      },
      {
        name: 'presupuesto_registro',
        path: 'registro',
        component: () =>
          import(/* webpackChunkName: "group-proyectos-dashboard" */ '@/giplan/presupuesto/registro/views/Registro'),
        meta: {
          code: 'gh-pres-repre'
        }
      },
      {
        name: 'opex_campo',
        path: 'opex-campo',
        component: () => import('@/giplan/presupuesto/opex/views/opex-campo/OpexCampo'),
        meta: {
          code: 'gh-pres-opex-campo'
        }
      },
      {
        name: 'opex_campo_nuevo',
        path: 'opex-campo/nuevo',
        component: () => import('@/giplan/presupuesto/opex/views/opex-campo/OpexCampoFormNuevo'),
        meta: {
          code: 'gh-pres-opex-campo-insert'
        }
      },
      {
        name: 'opex_campo_editar',
        path: 'opex-campo/editar',
        component: () => import('@/giplan/presupuesto/opex/views/opex-campo/OpexCampoFormEditar'),
        meta: {
          code: 'gh-pres-opex-campo-edit'
        }
      },
      {
        name: 'opex_campo_nueva_version',
        path: 'opex-campo/nueva-version',
        component: () => import('@/giplan/presupuesto/opex/views/opex-campo/OpexCampoFormNuevaVersion'),
        meta: {
          code: 'gh-pres-opex-campo-version'
        }
      },
      {
        name: 'opex_campo_ver_versiones',
        path: 'opex-campo/ver-versiones',
        component: () => import('@/giplan/presupuesto/opex/views/opex-campo/OpexCampoFormVerVersiones'),
        meta: {
          code: 'gh-pres-opex-campo-view'
        }
      },
      {
        name: 'opex_packing',
        path: 'opex-packing',
        component: () => import('@/giplan/presupuesto/opex/views/opex-packing/OpexPacking'),
        meta: {
          code: 'gh-pres-opex-pck'
        }
      },
      {
        name: 'opex_packing_nuevo',
        path: 'opex-packing/nuevo',
        component: () => import('@/giplan/presupuesto/opex/views/opex-packing/OpexPackingFormNuevo'),
        meta: {
          code: 'gh-pres-opex-pck-insert'
        }
      },
      {
        name: 'opex_packing_editar',
        path: 'opex-packing/editar',
        component: () => import('@/giplan/presupuesto/opex/views/opex-packing/OpexPackingFormEditar'),
        meta: {
          code: 'gh-pres-opex-pck-edit'
        }
      },
      {
        name: 'opex_packing_nueva_version',
        path: 'opex-packing/nueva-version',
        component: () => import('@/giplan/presupuesto/opex/views/opex-packing/OpexPackingFormNuevaVersion'),
        meta: {
          code: 'gh-pres-opex-pck-version'
        }
      },
      {
        name: 'opex_packing_ver_versiones',
        path: 'opex-packing/ver-versiones',
        component: () => import('@/giplan/presupuesto/opex/views/opex-packing/OpexPackingFormVerVersiones'),
        meta: {
          code: 'gh-pres-opex-pck-view'
        }
      },
      {
        name: 'opex_gasto_administrativo',
        path: 'opex-gasto-administrativo',
        component: () => import('@/giplan/presupuesto/opex/views/opex-gasto-administrativo/OpexGastoAdm'),
        meta: {
          code: 'gh-pres-opex-ga'
        }
      },
      {
        name: 'opex_gasto_administrativo_nuevo',
        path: 'opex-gasto-administrativo/nuevo',
        component: () => import('@/giplan/presupuesto/opex/views/opex-gasto-administrativo/OpexGastoAdmFormNuevo'),
        meta: {
          code: 'gh-pres-opex-ga-insert'
        }
      },
      {
        name: 'opex_gasto_administrativo_editar',
        path: 'opex-gasto-administrativo/editar',
        component: () => import('@/giplan/presupuesto/opex/views/opex-gasto-administrativo/OpexGastoAdmFormEditar'),
        meta: {
          code: 'gh-pres-opex-ga-edit'
        }
      },
      {
        name: 'opex_gasto_administrativo_nueva_version',
        path: 'opex-gasto-administrativo/nueva-version',
        component: () =>
          import('@/giplan/presupuesto/opex/views/opex-gasto-administrativo/OpexGastoAdmFormNuevaVersion'),
        meta: {
          code: 'gh-pres-opex-ga-version'
        }
      },
      {
        name: 'opex_gasto_administrativo_ver_versiones',
        path: 'opex-gasto-administrativo/ver-versiones',
        component: () =>
          import('@/giplan/presupuesto/opex/views/opex-gasto-administrativo/OpexGastoAdmFormVerVersiones'),
        meta: {
          code: 'gh-pres-opex-ga-view'
        }
      },
      {
        name: 'opex_ti',
        path: 'opex-ti',
        component: () => import('@/giplan/presupuesto/opex/views/opex-ti/OpexTI'),
        meta: {
          code: 'gh-pres-opex-ti'
        }
      },
      {
        name: 'opex_ti_nuevo',
        path: 'opex-ti/nuevo',
        component: () => import('@/giplan/presupuesto/opex/views/opex-ti/OpexTIFormNuevo'),
        meta: {
          code: 'gh-pres-opex-ti-insert'
        }
      },
      {
        name: 'opex_ti_editar',
        path: 'opex-ti/editar',
        component: () => import('@/giplan/presupuesto/opex/views/opex-ti/OpexTIFormEditar'),
        meta: {
          code: 'gh-pres-opex-ti-edit'
        }
      },
      {
        name: 'opex_ti_nueva_version',
        path: 'opex-ti/nueva-version',
        component: () => import('@/giplan/presupuesto/opex/views/opex-ti/OpexTIFormNuevaVersion'),
        meta: {
          code: 'gh-pres-opex-ti-version'
        }
      },
      {
        name: 'opex_ti_ver_versiones',
        path: 'opex-ti/ver-versiones',
        component: () => import('@/giplan/presupuesto/opex/views/opex-ti/OpexTIFormVerVersiones'),
        meta: {
          code: 'gh-pres-opex-ti-view'
        }
      },
      {
        name: 'capex_campo',
        path: 'capex-campo',
        component: () => import('@/giplan/presupuesto/capex/views/capex-campo/CapexCampo'),
        meta: {
          code: 'gh-pres-capex-campo'
        }
      },
      {
        name: 'capex_campo_nuevo',
        path: 'capex-campo/nuevo',
        component: () => import('@/giplan/presupuesto/capex/views/capex-campo/CapexCampoFormNuevo'),
        meta: {
          code: 'gh-pres-capex-campo-insert'
        }
      },
      {
        name: 'capex_campo_editar',
        path: 'capex-campo/editar',
        component: () => import('@/giplan/presupuesto/capex/views/capex-campo/CapexCampoFormEditar'),
        meta: {
          code: 'gh-pres-capex-campo-edit'
        }
      },
      {
        name: 'capex_campo_nueva_version',
        path: 'capex-campo/nueva-version',
        component: () => import('@/giplan/presupuesto/capex/views/capex-campo/CapexCampoFormNuevaVersion'),
        meta: {
          code: 'gh-pres-capex-campo-version'
        }
      },
      {
        name: 'capex_campo_ver_versiones',
        path: 'capex-campo/ver-versiones',
        component: () => import('@/giplan/presupuesto/capex/views/capex-campo/CapexCampoFormVerVersiones'),
        meta: {
          code: 'gh-pres-capex-campo-view'
        }
      },
      {
        name: 'real_observado',
        path: 'real-observado',
        component: () => import('@/giplan/presupuesto/real-observado/views/RealObservado'),
        meta: {
          code: '*'
        }
      },
      {
        name: 'tipos_gasto',
        path: 'tipos-gasto',
        component: () => import('@/giplan/presupuesto/tipos-gasto/views/TiposGasto'),
        meta: {
          code: 'gh-pres-tipo-costo'
        }
      },
      {
        name: 'cuentas_contables',
        path: 'cuentas-contables',
        component: () => import('@/giplan/presupuesto/cuentas-contables/views/CuentasContables'),
        meta: {
          code: 'gh-pres-cuenta-contable'
        }
      },
      {
        name: 'cuentas_mayores',
        path: 'cuentas-mayores',
        component: () => import('@/giplan/presupuesto/cuentas-mayores/views/CuentasMayores'),
        meta: {
          code: 'gh-pres-cuenta-mayores'
        }
      },
      {
        name: 'cecos',
        path: 'cecos',
        component: () => import('@/giplan/presupuesto/cecos/views/Cecos'),
        meta: {
          code: 'gh-pres-ceco'
        }
      },
      {
        name: 'articulos_servicios',
        path: 'articulos-servicios',
        component: () => import('@/giplan/presupuesto/articulos-servicios/views/ArticulosServicios'),
        meta: {
          code: 'gh-pres-articulos-servicios'
        }
      },
      {
        name: 'proyeccion_mo',
        path: 'proyeccion-mo',
        component: () => import('@/giplan/presupuesto/proyeccion-mo/views/ProyeccionMO'),
        meta: {
          code: 'gh-pres-proyeccion-mo'
        }
      }
    ]
  },
  {
    path: '/indicadores',
    component: () => import(/* webpackChunkName: "group-actas" */ '@/layouts/full-layout/Layout'),
    meta: {
      code: 'gh-ind'
    },
    children: [
      {
        name: 'indicadores/ficha_tecnica',
        path: 'ficha-tecnica',
        component: () =>
          import(
            /* webpackChunkName: "group-actas-dashboard" */ '@/giplan/indicadores/ficha_tecnica/views/FichaTecnica'
          ),
        meta: {
          code: 'gh-ind-hoj_norm'
        }
      },
      {
        name: 'indicadores/matriz',
        path: 'matriz',
        component: () =>
          import(/* webpackChunkName: "group-actas-dashboard" */ '@/giplan/indicadores/matriz/views/Matriz'),
        meta: {
          code: 'gh-ind-mis_ind'
        }
      }
    ]
  },
  {
    path: '/calendario-comites',
    component: () => import(/* webpackChunkName: "group-actas" */ '@/layouts/full-layout/Layout'),
    meta: {
      code: 'gh-cal'
    },
    children: [
      {
        name: 'calendario-comites-evento',
        path: 'evento',
        component: () =>
          import(/* webpackChunkName: "group-actas-dashboard" */ '@/giplan/calendario-comites/evento/views/Evento'),
        meta: {
          code: 'gh-cal-com_per'
        }
      },
      {
        name: 'calendario-comites-comite',
        path: 'comite',
        component: () =>
          import(/* webpackChunkName: "group-actas-dashboard" */ '@/giplan/calendario-comites/comite/views/Comite'),
        meta: {
          code: 'gh-cal-com_gh'
        }
      },
      {
        name: 'calendario-comites-tarea',
        path: 'tarea',
        component: () =>
          import(/* webpackChunkName: "group-actas-dashboard" */ '@/giplan/calendario-comites/tarea/views/Tarea'),
        meta: {
          code: 'gh-cal-proy'
        }
      },
      {
        name: 'calendario-comites-compromiso',
        path: 'compromiso',
        component: () =>
          import(
            /* webpackChunkName: "group-actas-dashboard" */ '@/giplan/calendario-comites/compromiso/views/Compromiso'
          ),
        meta: {
          code: 'gh-cal-act_reu'
        }
      },
      {
        name: 'calendario-comites/comite_matriz',
        path: 'comite-matriz',
        component: () =>
          import(
            /* webpackChunkName: "group-actas-dashboard" */ '@/giplan/calendario-comites/matriz_comite/views/MatrizComite'
          ),
        meta: {
          code: 'gh-cal-mat_com'
        }
      }
    ]
  },
  {
    path: '/documentacion',
    component: () => import(/* webpackChunkName: "group-documentacion" */ '@/layouts/full-layout/Layout'),
    meta: {
      code: 'gh-doc'
    },
    children: [
      {
        name: 'documentacion_dashboard',
        path: 'dashboard',
        component: () =>
          import(
            /* webpackChunkName: "group-documentacion-dashboard" */ '@/giplan/documentacion/dashboard/views/Dashboard'
          ),
        meta: {
          code: 'gh-doc-expl'
        }
      }
    ]
  },
  {
    path: '/kanban',
    component: () => import(/* webpackChunkName: "group-kanban" */ '@/layouts/full-layout/Layout'),
    meta: {
      code: 'gh-kan'
    },
    children: [
      {
        name: 'kanban_tablero',
        path: 'tablero',
        component: () =>
          import(/* webpackChunkName: "group-kanban-dashboard" */ '@/giplan/kanban/dashboard/views/Dashboard'),
        meta: {
          code: 'gh-kan-tablero'
        }
      }
    ]
  },
  {
    path: '/permisos',
    component: () => import(/* webpackChunkName: "group-kanban" */ '@/layouts/full-layout/Layout'),
    meta: {
      code: 'gh-perm'
    },
    children: [
      {
        name: 'usuarios',
        path: 'usuarios',
        component: () =>
          import(/* webpackChunkName: "group-kanban-dashboard" */ '@/giplan/permisos/usuarios/views/Usuarios'),
        meta: {
          code: 'gh-perm-usuario'
        }
      },
      {
        name: 'usuario_nuevo',
        path: 'usuario/nuevo',
        component: () =>
          import(/* webpackChunkName: "group-kanban-dashboard" */ '@/giplan/permisos/usuarios/views/UsuarioFormNuevo'),
        meta: {
          code: 'gh-perm-usuario'
        }
      },
      {
        name: 'usuario_editar',
        path: 'usuario/editar',
        component: () =>
          import(/* webpackChunkName: "group-kanban-dashboard" */ '@/giplan/permisos/usuarios/views/UsuarioFormEditar'),
        meta: {
          code: 'gh-perm-usuario'
        }
      }
    ]
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: () => import(/* webpackChunkName: "unauthorized" */ '@/components4x/authentication/Unauthorized'),
    meta: {
      code: '*'
    }
  },
  {
    path: '*',
    component: () => import('@/views/authentication/Error'),
    meta: {
      code: '*'
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  }
});

export default router;
