<template>
  <v-card class="mb-7">
    <v-toolbar flat dense v-if="heading">
      <v-toolbar-title>
        {{ heading }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-divider></v-divider>
    <div :class="classAux">
      <slot />
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'BaseCard',

  props: {
    heading: {
      type: String,
      default: null
    },
    classAux: {
      type: String,
      default: 'pa-4 pt-2'
    }
  },
  data: () => ({}),

  methods: {}
};
</script>
