class LoadSettingsService {
  constructor() {
    // this.token = 'tokenjob';
  }
  resources = () => ({
    proyeccionMO: {
      uri: `/v1/presupuesto/listar-proyeccion-mo`,
      method: ['get']
    },
    configuraciones: {
      uri: `/v1/autorizacion/listar-configuracion-usuario`,
      method: ['get']
    },
    cerrarSesion: {
      uri: `/v1/logout`,
      method: ['get']
    }
  });
}

export default LoadSettingsService;
