<template>
  <v-row class="page-breadcrumb">
    <v-col cols="12" sm="12" class="pa-0">
      <v-card class="px-2 custom-shaodow d-flex">
        <v-toolbar dense flat class="transparent pa-0" :height="height ? height : '45px'">
          <!--<v-toolbar-title class="pt-3">
            <span class="text--primary">{{ title }}</span>
          </v-toolbar-title>-->
          <v-toolbar-title class="d-flex">
            <v-breadcrumbs :items="breadcrumbs" class="pa-0">
              <template v-slot:divider v-if="breadcrumbs">
                <v-icon>mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item :href="item.href" :disabled="item.disabled" style="font-size: 18px;">
                  {{ item.text }}
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
          </v-toolbar-title>
          <!--<v-toolbar-title class="toolbar-extension transparent pb-3" slot="extension">
            <v-breadcrumbs :items="breadcrumbs" class="pa-0">
              <template v-slot:divider v-if="breadcrumbs">
                <v-icon>mdi-chevron-right</v-icon>
              </template>
            </v-breadcrumbs>
            <slot></slot>
          </v-toolbar-title>-->
          <v-spacer></v-spacer>
          <slot name="item-right"></slot>
        </v-toolbar>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'BaseBreadcrumb',
  props: {
    title: String,
    breadcrumbs: Array,
    icon: String,
    height: String
  },
  data: () => ({})
};
</script>
<style lang="scss">
.page-breadcrumb {
  margin: -20px -25px 27px -25px;
}
</style>
