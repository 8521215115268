//import fire from '@/fire';
//import { decryptData } from '@/components4x/utils/utils4x';
import HttpHandlerService from '@/services/httpHandlerService';
import LoadSettingsService from './LoadSettingsService';

/*async function loadPermissions(store) {
  const id = localStorage.getItem('lrd5qwlr674');
  if (id === null) return;

  const role = await store.getters.getRole;
  if (role === '') return;

  const ref = fire.firestore().collection('modules_auth');

  const response = await ref.where('roles', 'array-contains', role).get();

  // console.log('response', response.docs);

  if (response.empty) return;

  const permissions = [];

  response.forEach((doc) => {
    permissions.push(doc.data().code);
  });

  await store.dispatch('updatePermission', permissions);
  console.log(permissions);
}*/

async function loadSettings(store, vuetify) {
  /*const id = localStorage.getItem('lrd5qwlr674');
  if (id === null) return;

  const decryptId = decryptData(id);

  const config = await fire
    .firestore()
    .collection('users')
    .doc(decryptId)
    .get();

  if (!config.exists) return;

  // console.log(vuetify, vuetify);

  vuetify.framework.theme.dark = config.data().themedark;
  store.commit('SET_SIDEBAR_COLOR', config.data().sidebarcolor);
  store.commit('SET_THEME_COLOR', config.data().themecolor);
  await store.dispatch('updateRole', config.data().role);
  await store.dispatch('updateProfile', config.data().profile);*/

  /**
   *
   */

  if (!window.location.pathname.includes('/auth/login')) {
    const http = new HttpHandlerService(new LoadSettingsService(), null, {
      token: true,
      name: 'token'
    });

    const res = await http.get().execResource('configuraciones');
    if (res[0].activo == 1) {
      vuetify.framework.theme.dark = Number(res[0].themedark);
      store.commit('SET_SIDEBAR_COLOR', res[0].sidebarcolor);
      store.commit('SET_THEME_COLOR', res[0].themecolor);
      await store.dispatch('updateProfile', res[0].permisos);
      await store.dispatch('updateRole', res[0].role);
      await store.dispatch('updatePermission', res[0].module_code);

      const res2 = await http.get().execResource('proyeccionMO');
      await store.dispatch('cargarProyeccionMO', res2);
    } else {
      const res = await http.get().execResource('cerrarSesion');
      if (res.status) {
        localStorage.removeItem('token');
        localStorage.removeItem('lrd5qwlr674');
        localStorage.removeItem('iow5csjr398');
        localStorage.removeItem('nc4rhtcc976');
        localStorage.removeItem('k3rtxqdp234');
        localStorage.removeItem('oq1rcizm635');
        await store.dispatch('updateReset');
        window.location.href = process.env.VUE_APP_CLIENT_LOGIN_URL;
      }
    }
  }
}

//export { loadSettings, loadPermissions };
export { loadSettings };
